import './Icon.scss';
import React, { FC } from 'react';
import ArrowUp from './icons/arrow-up.svg';
import ArrowDown from './icons/arrow-down.svg';
import ArrowLeft from './icons/arrow-left.svg';
import ArrowRight from './icons/arrow-right.svg';
import Check from './icons/check.svg';
import CheckCircle from './icons/check-circle.svg';
import Close from './icons/close.svg';
import Exclamation from './icons/exclamation.svg';
import { IconProps } from './Icon.types';
import Info from './icons/info.svg';
import Plus from './icons/plus.svg';
import Select from './icons/select.svg';
import SelectAsc from './icons/select-asc.svg';
import SelectDesc from './icons/select-desc.svg';
import Skip from './icons/skip.svg';
import Trash from './icons/trash.svg';
import c from 'classnames';

const wrap = (Icon: any, { ...props }: IconProps) => {
  const classes = c(props.className, 'Icon', {
    [`Icon--${props.name}`]: props.name,
    '-s': props.size === 'small',
  });

  return <span className={classes}>{Icon}</span>;
};

const Icon: FC<IconProps> = ({ className, name, size }) => {
  const props = { className, name, size };
  const n = name;

  if (n === 'arrowUp') return wrap(<ArrowUp />, props);
  if (n === 'arrowDown') return wrap(<ArrowDown />, props);
  if (n === 'arrowLeft') return wrap(<ArrowLeft />, props);
  if (n === 'arrowRight') return wrap(<ArrowRight />, props);
  if (n === 'check') return wrap(<Check />, props);
  if (n === 'checkCircle') return wrap(<CheckCircle />, props);
  if (n === 'close') return wrap(<Close />, props);
  if (n === 'exclamation') return wrap(<Exclamation />, props);
  if (n === 'info') return wrap(<Info />, props);
  if (n === 'skip') return wrap(<Skip />, props);
  if (n === 'select') return wrap(<Select />, props);
  if (n === 'selectDesc') return wrap(<SelectDesc />, props);
  if (n === 'selectAsc') return wrap(<SelectAsc />, props);
  if (n === 'trash') return wrap(<Trash />, props);
  if (n === 'plus') return wrap(<Plus />, props);

  return null;
};

export default Icon;

import { useEffect } from 'react';

const useScrollTop = (
  scroll: boolean | string = true,
  behavior: 'auto' | 'smooth' = 'auto',
) => {
  useEffect(() => {
    if (scroll) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior,
        });
      }, 5);
    }
  }, [scroll]);
};

export default useScrollTop;

import { MutableRefObject, useEffect } from 'react';
import { debounce } from './utils';

const useResize = (
  onResize: () => void,
  deps: Array<MutableRefObject<any> | unknown> = [],
  debounceTime: number = 100,
) => {
  useEffect(() => {
    window.addEventListener('resize', debounce(onResize, debounceTime));

    return () => window.removeEventListener('resize', onResize);
  }, [...deps, onResize]);
};

export default useResize;

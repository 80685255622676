import './Loader.scss';

import React, { FC } from 'react';

import { LoaderProps } from './Loader.types';

const Loader: FC<LoaderProps> = () => {
  return <div className="Loader" />;
};

export default Loader;

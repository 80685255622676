import * as R from 'ramda';
import './Number.scss';
import { animate } from 'framer-motion';
import Heading from '../Heading/Heading';
import React, { FC, useEffect, useRef, useState, useCallback } from 'react';

import { NumberProps } from './Number.types';
import { countDecimals, isNumber, isNumeric, toNumber } from '../../lib/utils';
import useResize from '../../lib/useResize';
import c from 'classnames';

const Number: FC<NumberProps> = ({
  align = 'right',
  animation = true,
  className,
  decimal = 2,
  negative = false,
  number = '--.--',
  scale = false,
  scaleAfter = 6,
  size = 'medium',
  subtitle,
  subtitleColor = 'default',
  subtitleSize = 'default',
  truncate = false,
  truncateAfter = 5,
  unit = 'kg CO₂e \n/ kg',
  unitUppercase,
  unitPosition = 'right',
  weight = 'default',
}) => {
  const validVal = !R.isEmpty(number) && isNumeric(number);
  const numVal = validVal ? number : '--.--';
  const amountOfDecimals = countDecimals(numVal);
  const decimals = decimal || decimal === 0 ? decimal : amountOfDecimals;
  const numValOrString =
    !R.isNil(numVal) && isNumber(numVal)
      ? toNumber(numVal).toFixed(decimals)
      : numVal;
  const numberLength = numVal
    ? toNumber(numVal).toFixed().toString().length + decimals
    : 0;
  const [isEllipsis, setIsEllipsis] = useState(false);
  const isTruncate = truncate && numberLength > truncateAfter;
  const isScale = scale && numberLength > scaleAfter;
  const xLarge = size === 'xLarge';
  const large = size === 'large';
  const medium = size === 'medium';
  const small = size === 'small';
  const xSmall = size === 'xSmall';
  const unitPosBottom = unitPosition === 'bottom';
  const unitPosInNumber = unitPosition === 'inNumber';
  const classes = c(className, 'Number', {
    '-color:negative': negative,
    '-size:xs':
      xSmall ||
      (large && isScale) ||
      (xLarge && isScale && numberLength > scaleAfter * 3),
    '-size:s':
      small ||
      (large && isScale) ||
      (xLarge && isScale && numberLength > scaleAfter * 2),
    '-size:m':
      medium ||
      (large && isScale) ||
      (xLarge && isScale && numberLength > scaleAfter * 1.5),
    '-size:l': large || (xLarge && isScale),
    '-size:xl': xLarge,
    '-unit:position:bottom': unitPosBottom,
    '-unit:percent': unit === '%',
    '-align:center': align === 'center',
    '-weight:strong': weight === 'strong',
    'is-truncate': isTruncate,
  });
  const unitSingleLine = unit.replace(/(\r\n|\n|\r)/gm, '');
  const numberValueRef = useRef<any>();
  const numberRef = useRef<any>();
  const [prevNumVal, setPrevNumVal] = useState<any>(0);
  let unitVal = unit;
  let unitValUpper: any =
    unit === 'kg CO₂e \n/ kg'
      ? 'KG CO₂e / KG'
      : !unitUppercase
      ? unit
      : unitUppercase;

  useEffect(() => {
    const node = numberValueRef.current;
    if (!node) return;

    if (!isNumber(numVal) || !animation) {
      node.textContent = numValOrString;
      return;
    }

    if (isNumber(numVal)) {
      const prevNum = isNumber(prevNumVal) ? prevNumVal : 0;
      const animation = animate(prevNum, numVal, {
        duration: 1,
        onUpdate(value) {
          node.textContent = value.toFixed(decimals);
        },
        onComplete: () => {
          const { clientWidth, scrollWidth } = numberRef.current;
          setIsEllipsis(scrollWidth > clientWidth);
        },
      });

      if (prevNum != numVal) {
        setPrevNumVal(numVal);
      }

      return () => animation.stop();
    }
  }, [number]);

  if (truncate) {
    const setTruncateOnResize = useCallback(() => {
      if (!numberRef.current) return;
      const { clientWidth, scrollWidth } = numberRef.current;
      setIsEllipsis(scrollWidth > clientWidth);
    }, [numberRef]);

    useResize(setTruncateOnResize, [numberRef]);
  }

  return (
    <div className={classes} title={`${numVal} ${unitSingleLine}`}>
      {subtitle && (
        <Heading
          className="Number-subtitle"
          negative={negative}
          subtitle={subtitle}
          subtitleColor={subtitleColor}
          subtitleSize={subtitleSize}
        />
      )}
      <div className="Number-inner">
        <div className="Number-number" ref={numberRef} key="Number-number">
          <span ref={numberValueRef} />
          {unitPosInNumber && !R.isEmpty(numVal) && (
            <span className="Number-number-unit">{unitVal}</span>
          )}
        </div>
        {!unitPosBottom && !unitPosInNumber && (
          <div className="Number-unit">{unitVal}</div>
        )}
        {unitPosBottom && (
          <Heading
            className="Number-unitBottom"
            negative={negative}
            subtitle={unitValUpper}
            subtitleCase={false}
            {...(negative && { subtitleColor: 'strong' })}
          />
        )}
      </div>
      {isEllipsis && isTruncate && (
        <Heading
          aria-hidden="true"
          className="Number-numberDetailed"
          negative={negative}
          subtitle={numValOrString}
          subtitleSize="xSmall"
          subtitleColor="strong"
          subtitleSuffix={unitValUpper}
          subtitleSuffixCase={false}
        />
      )}
    </div>
  );
};

export default Number;

import './Wrap.scss';

import React, { FC } from 'react';

import { WrapProps } from './Wrap.types';
import c from 'classnames';

const Wrap: FC<WrapProps> = ({ className, children, size }) => {
  const classes = c('Wrap', className, {
    '-s': size === 'small',
    '-xs': size === 'xSmall',
  });

  return <div className={classes}>{children}</div>;
};

export default Wrap;

/* ======
 * Animations (https://www.framer.com/motion)
 * ======
 *
 * https://www.framer.com/api/motion/types
 */

export const easing = [0.76, 0, 0.18, 1];
export const duration = 0.6;
export const durationFast = 0.3;
export const transDefault = {
  duration: duration,
  ease: easing,
};
export const transDefaultFast = {
  duration: durationFast,
  ease: easing,
};

/* ======
 * Chart config (https://nivo.rocks)
 * ======
 *
 * https://nivo.rocks/guides/theming
 */

export const chart = {
  default: {
    theme: {
      background: 'transparent',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      textColor: 'inherit',
      tooltip: {
        container: {
          padding: 0,
          background: 'transparent',
          boxShadow: 'none',
        },
      },
    },
  },
  donut: {
    theme: {},
  },
  bar: {
    theme: {},
  },
};

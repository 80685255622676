import './Overlay.scss';

import React, { FC } from 'react';
import { duration, easing } from '../../lib/config';

import { OverlayProps } from './Overlay.types';
import c from 'classnames';

const Overlay: FC<OverlayProps> = ({ className, motion, zIndex }) => {
  const classes = c('Overlay', className);

  return (
    <motion.div
      animate={{
        opacity: 1,
      }}
      className={classes}
      exit={{
        opacity: 0,
      }}
      initial={{
        opacity: 0,
      }}
      style={{
        ['--Overlay-zIndex' as any]: zIndex,
      }}
      transition={{
        duration,
        ease: easing,
      }}
    />
  );
};

export default Overlay;

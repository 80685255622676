import './CardBlock.scss';
import * as R from 'ramda';

import React, { FC } from 'react';
import { isBoolean } from '../../lib/utils';
import { CardBlockProps } from './CardBlock.types';
import Number from '../Number/Number';
import c from 'classnames';

const CardBlock: FC<CardBlockProps> = ({
  children,
  className,
  isDisabled = false,
  heading,
  headingResult = false,
  headingResultUnit,
  headingResultAnimation = true,
  isActive,
  size,
  toolbarButtons,
}) => {
  const classes = c('CardBlock', className, {
    'is-active': isActive,
    'is-disabled': isDisabled,
    'has-toolbarButtons': toolbarButtons && !isDisabled,
    '-s': size === 'small',
  });

  return (
    <div className={classes}>
      <header className="CardBlock-header">
        <h3 className="CardBlock-header-heading">{heading}</h3>
        {!R.isEmpty(headingResult) && !isBoolean(headingResult) && (
          <div className="CardBlock-header-result">
            <Number
              animation={headingResultAnimation}
              number={typeof headingResult != 'boolean' ? headingResult : null}
              size={size === 'small' ? 'xSmall' : 'medium'}
              unit={headingResultUnit}
            />
          </div>
        )}
        {!isDisabled && (
          <div className="CardBlock-toolbar">
            <div className="Buttons -full">{toolbarButtons}</div>
          </div>
        )}
      </header>
      {children && <div className="CardBlock-content">{children}</div>}
    </div>
  );
};

export default CardBlock;

import './Section.scss';

import React, { FC, Fragment } from 'react';

import { SectionProps } from './Section.types';
import Wrap from '../Wrap/Wrap';
import c from 'classnames';

const Section: FC<SectionProps> = ({
  className,
  border,
  children,
  wrapSize,
  wrap = true,
}) => {
  const Wrapper = wrap ? Wrap : Fragment;
  const classes = c('Section', className, {
    '-border': border === true,
  });

  return (
    <section className={classes}>
      <Wrapper size={wrapSize}>{children}</Wrapper>
    </section>
  );
};

export default Section;

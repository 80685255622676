import "./Avatar.scss";

import { AvatarProps } from "./Avatar.types";
import React from "react";

const Avatar: React.FC<AvatarProps> = ({
  picture = "http://www.gravatar.com/avatar/?d=identicon",
  nick,
  email,
}) => {
  const pictureSrc = picture;
  const nickname = nick;
  const userEmail = email || "-";

  return (
    <div className="Avatar">
      <figure className="Avatar-figure">
        <img className="Avatar-figure-img" src={pictureSrc} alt={nickname} />
      </figure>
      <div className="Avatar-content">
        <p className="Avatar-name">{nickname}</p>
        <p className="Avatar-subtitle">{userEmail}</p>
      </div>
    </div>
  );
};

export default Avatar;

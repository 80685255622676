import './Dropdown.scss';

import {
  DropdownProps,
  HeadingProps,
  ItemProps,
  ToggleProps,
} from './Dropdown.types';
import React, { Children, FC, cloneElement } from 'react';

import { Squash as Hamburger } from 'hamburger-react';
import c from 'classnames';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

// Convert to use button later on
const Toggle: FC<ToggleProps> = ({
  forwardedRef = null,
  icon = false,
  isOpen = false,
  modifier = 'default',
  text = false,
  ...props
}) => {
  const classes = c('Button Dropdown-toggleButton', {
    'Button--plain': modifier === 'plain',
    'Button--default': modifier === 'default',
  });
  return (
    <button className={classes} ref={forwardedRef} {...props}>
      <div className="Button-inner">
        <div className="Button-item">
          {text && <span className="Button-text">{text}</span>}
          {icon && (
            <span className="Button-icon">
              {icon === 'hamburger' && (
                <Hamburger toggled={isOpen} size={18} duration={0.3} />
              )}
            </span>
          )}
        </div>
        <div className="Button-spacer">
          {text && <span className="Button-text">{text}</span>}
          {icon && (
            <span className="Button-icon">
              {icon === 'hamburger' && (
                <Hamburger toggled={isOpen} size={18} duration={0.3} />
              )}
            </span>
          )}
        </div>
      </div>
    </button>
  );
};

const Item: FC<ItemProps> = React.forwardRef<any, ItemProps>(
  (
    {
      children,
      className,
      href = '',
      modifier = 'default',
      onClick = () => false,
      type = 'item',
      ...props
    },
    ref,
  ) => {
    const classes = c('Dropdown-item', className, {
      'Dropdown-item--separator': modifier === 'separator',
    });

    return (
      <div className={classes}>
        {type === 'item' && (
          <div className="Dropdown-text" ref={ref} {...props}>
            {children}
          </div>
        )}
        {type === 'button' && (
          <button
            className="Dropdown-button"
            ref={ref}
            onClick={onClick}
            {...props}
          >
            {children}
          </button>
        )}
        {type === 'link' && (
          <a
            className="Dropdown-link"
            href={href}
            ref={ref}
            onClick={onClick}
            {...props}
          >
            {children}
          </a>
        )}
      </div>
    );
  },
);

const Heading: FC<HeadingProps> = React.forwardRef<any, HeadingProps>(
  ({ heading = '', modifier = 'default', ...props }, ref) => {
    const classes = c('Dropdown-item Dropdown-item--heading', {
      'Dropdown-item--separator': modifier === 'separator',
    });
    return (
      <div className={classes}>
        <h6 className="Dropdown-heading" ref={ref} {...props}>
          {heading}
        </h6>
      </div>
    );
  },
);

const Dropdown: DropdownProps = ({
  align,
  buttonStyle,
  buttonText,
  className,
  children,
  icon,
}) => {
  const amountOfItems = Children.count(children);
  const { buttonProps, itemProps, isOpen } = useDropdownMenu(amountOfItems);
  const classes = c('Dropdown', className, {
    'is-open': isOpen,
    '-alignRight': align === 'right',
    '-hasIcon': icon,
  });
  const { ref, ...rest } = buttonProps;

  return (
    <div className={classes}>
      <Toggle
        forwardedRef={ref}
        icon={icon}
        isOpen={isOpen}
        modifier={buttonStyle}
        text={buttonText}
        {...rest}
      />

      <div className="Dropdown-menu" role="menu">
        {Children.map(children, (child: any, i) => {
          const displayName = child?.type.displayName;

          if (
            displayName === 'DropdownItem' ||
            displayName === 'DropdownHeading'
          ) {
            const { ref, ...rest } = itemProps[i];
            return cloneElement(child, {
              ref,
              ...rest,
            });
          }
        })}
      </div>
    </div>
  );
};

Item.displayName = 'DropdownItem';
Heading.displayName = 'DropdownHeading';
Dropdown.Item = Item;
Dropdown.Heading = Heading;

export default Dropdown;

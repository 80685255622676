import * as R from 'ramda';

export const isBoolean = (value: any) => {
  return typeof value === 'boolean';
};

export const isNumber = (number: any) => {
  return R.is(Number, number);
};

export const isString = (string: any) => {
  return R.is(String, string);
};

export const toNumber = (number: string | number) => {
  return typeof number === 'string' ? parseFloat(number) : number;
};

export const isNumeric = (number: any) => {
  if (R.isEmpty(number) || R.isNil(number)) return false;
  return !Number.isNaN(Number(number)) && !isNaN(parseFloat(number.toString()));
};

export const countDecimals = (number: number | string | undefined | null) => {
  if (
    R.isNil(number) ||
    R.isEmpty(number) ||
    !isNumeric(number) ||
    Number.isInteger(toNumber(number))
  ) {
    return 0;
  }

  const num = toNumber(number);
  const text = number.toString();

  if (text.indexOf('e-') > -1) {
    const [, trail] = text.split('e-');
    const deg = parseInt(trail, 10);
    return deg;
  }

  if (Math.floor(num) !== number) {
    return number.toString().split('.')[1].length || 0;
  }

  return 0;
};

export const getCSSVarValue = (prop: string) => {
  if (prop[0] != '-') prop = `--${prop}`;

  const value = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`${prop.trim()}`);
  return value.trim();
};

export const getCSSVarProps = (filterPrefix: string = '') => {
  if (filterPrefix[0] != '-') filterPrefix = `--${filterPrefix}`;

  return Array.from(document.styleSheets)
    .filter(
      sheet =>
        sheet.href === null || sheet.href.startsWith(window.location.origin),
    )
    .reduce(
      (acc: Array<any>, sheet: any) =>
        (acc = [
          ...acc,
          //@ts-ignore
          ...Array.from(sheet.cssRules).reduce(
            (def: any, rule: any) =>
              (def =
                rule.selectorText === ':root'
                  ? [
                      ...def,
                      ...Array.from(rule.style).filter((name: any) =>
                        name.startsWith(`${filterPrefix}`),
                      ),
                    ]
                  : def),
            [],
          ),
        ]),
      [],
    );
};

export const getCSSVarProp = (prop: string, filterPrefix?: string) => {
  if (prop[0] != '-') prop = `--${prop}`;
  if (getCSSVarProps(filterPrefix).includes(prop)) return `var(${prop})`;
  return false;
};

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number,
): ((...args: Parameters<F>) => ReturnType<F>) => {
  let timeout = 0;

  const debounced = (...args: any[]) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), waitFor);
  };

  return (debounced as unknown) as (...args: Parameters<F>) => ReturnType<F>;
};

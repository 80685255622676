import './Illustration.scss';

import React, { FC } from 'react';

import Consumption from './colored/consumption.svg';
import ConsumptionOutline from './outlined/consumption.svg';
import Delivery from './colored/delivery.svg';
import DeliveryOutline from './outlined/delivery.svg';
import EndOfLife from './colored/end-of-life.svg';
import EndOfLifeOutline from './outlined/end-of-life.svg';
import { IllustrationProps } from './Illustration.types';
import LandUse from './colored/land-use.svg';
import LandUseOutline from './outlined/land-use.svg';
import Packaging from './colored/packaging.svg';
import PackagingOutline from './outlined/packaging.svg';
import Production from './colored/production.svg';
import ProductionOutline from './outlined/production.svg';
import RawMaterials from './colored/raw-materials.svg';
import RawMaterialsOutline from './outlined/raw-materials.svg';
import Retail from './colored/retail.svg';
import RetailOutline from './outlined/retail.svg';
import Sourcing from './colored/sourcing.svg';
import SourcingOutline from './outlined/sourcing.svg';
import c from 'classnames';

const wrap = (Illustration: any, { ...props }: IllustrationProps) => {
  const classes = c(props.className, 'Illustration', {
    [`Illustration--${props.name}`]: props.name,
    '-s': props.size === 'small',
  });
  return <span className={classes}>{Illustration}</span>;
};

const Illustration: FC<IllustrationProps> = ({
  className,
  name,
  outline,
  size,
}) => {
  const props = { className, name, size };
  const o = outline;
  const n = name;

  if (n === 'consumption')
    return wrap(o ? <ConsumptionOutline /> : <Consumption />, props);
  if (n === 'delivery')
    return wrap(o ? <DeliveryOutline /> : <Delivery />, props);
  if (n === 'endOfLife')
    return wrap(o ? <EndOfLifeOutline /> : <EndOfLife />, props);
  if (n === 'landUse') return wrap(o ? <LandUseOutline /> : <LandUse />, props);
  if (n === 'packaging')
    return wrap(o ? <PackagingOutline /> : <Packaging />, props);
  if (n === 'production')
    return wrap(o ? <ProductionOutline /> : <Production />, props);
  if (n === 'rawMaterials')
    return wrap(o ? <RawMaterialsOutline /> : <RawMaterials />, props);
  if (n === 'retail') return wrap(o ? <RetailOutline /> : <Retail />, props);
  if (n === 'sourcing')
    return wrap(o ? <SourcingOutline /> : <Sourcing />, props);

  return null;
};

export default Illustration;

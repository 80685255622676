import "./Switch.scss";

import React, { FC } from "react";

import { SwitchProps } from "./Switch.types";
// @ts-ignore
import Toggle from "react-toggle";

const Switch: FC<SwitchProps> = ({
  onChange,
  id,
  checked,
  label,
  disabled,
  ...props
}) => {
  return (
    <label className="Switch">
      {label && <span className="Switch-label">{label}</span>}
      <Toggle
        checked={checked}
        id={id}
        name={id}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
    </label>
  );
};

export default Switch;

import * as R from 'ramda';
import React, { Component, ChangeEvent, ReactNode } from 'react';
import Input from '../Input/Input';
import { InputProps } from '../Input/Input.types';
import { debounce } from '../../lib/utils';

interface DebouncedInputProps extends InputProps {
  debounceTime?: number;
  initialValue: any;
  onChange: (value: string) => void;
}

interface DebouncedState {
  debounced: any;
  isLoading: boolean;
  value: InputProps['value'];
}

export class DebouncedInput extends Component<
  DebouncedInputProps,
  DebouncedState
> {
  constructor(props: DebouncedInputProps) {
    super(props);
    const debouncedFn = debounce(
      newValue => props.onChange(newValue),
      props.debounceTime ?? 500,
    );

    this.state = {
      debounced: debouncedFn,
      isLoading: false,
      value: props.initialValue || '',
    };
  }

  componentDidUpdate(prevProps: DebouncedInputProps): void {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        ...this.state,
        isLoading: false,
        value: this.props.initialValue,
      });
    }
  }

  render(): ReactNode {
    const omitProps = R.omit(['onChange', 'value', 'isLoading'], this.props);
    const { type, initialValue } = this.props;

    return (
      <Input
        isLoading={this.state.isLoading}
        key={this.props.id}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          let shouldLoad = !R.isEmpty(value) && value != initialValue;

          /**
           * Check if last digit is 0 to disable loading because sometimes new
           * initial value may not match with the target value with more leading
           * zeros. e.g. initialValue: 2.00 & value: 2.000 -> falsy loading.
           * Default input type is 'number' in `Input.tsx`
           */
          if (type != 'text') {
            const valString = value.toString();
            const isDecimal = valString.includes('.');

            if (isDecimal) {
              shouldLoad = shouldLoad && valString.slice(-1) != '0';
            }

            if (valString.startsWith('00') && !isDecimal) {
              shouldLoad = false;
            }
          }

          this.setState({
            isLoading: shouldLoad ? true : false,
            value: value,
          });

          this.state.debounced(value);
        }}
        value={this.state.value}
        {...omitProps}
      />
    );
  }
}

import './Select.scss';

import React, { FC } from 'react';

import Icon from '../Icon/Icon';
import { default as ReactSelect } from 'react-select';
import { SelectProps } from './Select.types';
import c from 'classnames';

const Select: FC<SelectProps> = ({
  autoFocus = false,
  className,
  disabled = false,
  error,
  isMulti = false,
  id,
  info,
  infoOnClick,
  isError,
  isLoading = false,
  isWarning,
  label = '',
  labelSuffix,
  isOpen = false,
  modifier,
  onChange,
  options = [],
  placeholder = 'Select',
  value,
  warning,
}) => {
  const isWarn = !isError && isWarning;
  const classes = c('Select', className, {
    'Select--button': modifier === 'button',
    'is-disabled': disabled,
    'is-error': isError,
    'is-warning': isWarn,
  });
  const hasLabel = label || (info && infoOnClick);
  const IconPlus = ({ innerRef, innerProps, name }: any) => (
    <div
      className="Select-select__indicator Select-select__dropdown-indicator"
      ref={innerRef}
      {...innerProps}
    >
      <Icon name="plus" size="small" />
    </div>
  );

  const components = {
    ...(modifier === 'button' && { DropdownIndicator: IconPlus }),
  };

  return (
    <div className={classes}>
      {hasLabel && (
        <div className="Select-heading">
          {label && (
            <label className="Select-label" htmlFor={id}>
              {label}
              {labelSuffix && (
                <span className="Select-label-suffix"> {labelSuffix}</span>
              )}
            </label>
          )}
          {info && infoOnClick && (
            <button onClick={infoOnClick} className="Select-info" type="button">
              <Icon name="info" size="small" />
            </button>
          )}
        </div>
      )}
      <ReactSelect
        autoFocus={autoFocus}
        className="Select-select"
        classNamePrefix="Select-select"
        components={components}
        id={id}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={isMulti}
        onChange={onChange}
        options={options}
        value={value}
        {...(isOpen && { menuIsOpen: true })}
        {...(placeholder && { placeholder: placeholder })}
      />
      {isError && <span className="Select-error">{error}</span>}
      {isWarn && <span className="Select-warning">{warning}</span>}
    </div>
  );
};

export default Select;

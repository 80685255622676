import React, { FC, ElementType } from 'react';
import * as R from 'ramda';

import { HeadingProps } from './Heading.types';
import './Heading.scss';
import c from 'classnames';

const Heading: FC<HeadingProps> = ({
  className,
  children,
  negative = false,
  size = 'h4',
  subtitle,
  subtitleCase = true,
  subtitleColor = 'default',
  subtitleSize = 'default',
  subtitleTruncate = false,
  subtitleSuffix,
  subtitleSuffixCase = true,
  tag,
}) => {
  const classes = c(className, 'Heading', {
    '-color:negative': negative,
  });
  const subtitleClasses = c('Heading-subtitle', {
    '-size:xs': subtitleSize === 'xSmall',
    '-color:strong': subtitleColor === 'strong',
    '-text:transform:none': !subtitleCase,
    '-text:truncate': subtitleTruncate,
    '-suffix:text:transform:none': !subtitleSuffixCase,
  });
  const headingClasses = c('Heading-heading', {
    [`${size}`]: !R.isNil(tag), // Uses helpers
  });

  const Tag: ElementType = !R.isNil(tag) ? tag : size;
  const SubtitleTag: ElementType =
    R.isNil(children) && !R.isNil(tag) ? tag : 'h6';

  return (
    <div className={classes}>
      {subtitle && (
        <SubtitleTag className={subtitleClasses}>
          {subtitle}
          {subtitleSuffix && (
            <span className="Heading-subtitle-suffix"> {subtitleSuffix}</span>
          )}
        </SubtitleTag>
      )}
      {children && <Tag className={headingClasses}>{children}</Tag>}
    </div>
  );
};

export default Heading;

import './Card.scss';

import React, { FC } from 'react';

import Button from '../Button/Button';
import { CardProps } from './Card.types';
import Name from '../Name/Name';
import c from 'classnames';

const Card: FC<CardProps> = ({
  buttonText,
  className,
  children,
  footerRight,
  footerSubtitle,
  heading,
  href,
  name,
  onClick,
  subtitle,
  subtitlePrimary,
  subtitleSecondary,
}) => {
  const hasSubtitles = subtitle || subtitlePrimary || subtitleSecondary;
  const hasFooterContent = footerSubtitle || footerRight;
  const linksTo = href && onClick;
  const classes = c('Card', className);

  return (
    <div className={classes}>
      {linksTo && (
        <a className="Card-link" href={href} onClick={onClick}>
          <span className="hideVisually">
            {buttonText} {heading}
          </span>
        </a>
      )}
      {name && (
        <div className="Card-top">
          {name && (
            <Name
              className="Card-name"
              name={name}
              parentHover={false}
              siblingsHover
            />
          )}
        </div>
      )}
      <div className="Card-inner">
        <header className="Card-header">
          <h3 className="Card-heading">{heading}</h3>
          {hasSubtitles && (
            <div className="Card-subtitles">
              {subtitle && <div className="Card-subtitle">{subtitle}</div>}
              {subtitlePrimary && (
                <div className="Card-subtitle">{subtitlePrimary}</div>
              )}
              {subtitleSecondary && (
                <div className="Card-subtitle">{subtitleSecondary}</div>
              )}
            </div>
          )}
        </header>
        {children && <div className="Card-content">{children}</div>}
        {buttonText && (
          <Button
            aria-hidden={true}
            className="Card-button"
            siblingsHover
            size="small"
            tabIndex={-1}
          >
            {buttonText}
          </Button>
        )}
      </div>
      {hasFooterContent && (
        <footer className="Card-footer">
          {footerSubtitle && (
            <div className="Card-footer-col">{footerSubtitle}</div>
          )}
          {footerRight && <div className="Card-footer-col">{footerRight}</div>}
        </footer>
      )}
    </div>
  );
};

export default Card;

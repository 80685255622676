import './List.scss';

import * as R from 'ramda';

import { ListItemProps, ListProps } from './List.types';
import React, { Children, FC, cloneElement } from 'react';
import c from 'classnames';

export const ListItem: FC<ListItemProps> = ({
  heading,
  truncate,
  value,
  type = 'definition',
}) => {
  if (type === 'definition') {
    return (
      <div className="List-item">
        <dt
          className="List-heading"
          {...(truncate && { title: heading?.toString() })}
        >
          {heading}
        </dt>
        <dd className="List-value">{value}</dd>
      </div>
    );
  }

  return null;
};

const List: ListProps = ({
  className,
  children,
  type = 'definition',
  truncate,
  unit,
}) => {
  const itemsOnly = Children.map(children, (child: any, i) => {
    const displayName = child?.type.displayName;
    if (displayName === 'ListItem') {
      return cloneElement(child, {
        truncate,
      });
    }
  });
  const listUnit = R.is(String, unit) ? unit : `kg CO₂e / kg`;
  const classes = c(className, 'List', {
    'List--dl': type === 'definition',
    '-text:truncate': truncate,
  });

  if (type === 'definition') {
    return (
      <dl className={classes}>
        {unit && <div className="List-unit">{listUnit}</div>}
        {itemsOnly}
      </dl>
    );
  }

  return null;
};

ListItem.displayName = 'ListItem';
List.Item = ListItem;

export default List;

import './Input.scss';

import { AnimatePresence, motion } from 'framer-motion';
import React, { ClipboardEvent, FC, KeyboardEvent, createRef } from 'react';

import Icon from '../Icon/Icon';
import { InputProps } from './Input.types';
import c from 'classnames';
import { transDefault } from '../../lib/config';

const Input: FC<InputProps> = ({
  autoFocus = false,
  className,
  defaultValue,
  disabled = false,
  enableNegativeValues,
  helper,
  required,
  error,
  id,
  info,
  infoOnClick,
  isError,
  isLoading,
  isWarning,
  label,
  labelSuffix,
  max,
  min,
  modifier,
  onChange,
  placeholder,
  step,
  type = 'number',
  unit,
  value,
  warning,
}) => {
  const isWarn = !isError && isWarning;
  const classes = c('Input', className, {
    'Input--default': modifier === 'default',
    'is-loading': isLoading,
    'is-disabled': disabled,
    'is-error': isError,
    'is-warning': isWarn,
  });
  const hasLabel = label || (info && infoOnClick);
  const inputRef = createRef<HTMLInputElement>();
  const positiveNumberValuesOnly = type === 'number' && !enableNegativeValues;
  const onWheel = () => inputRef?.current?.blur();
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      inputRef?.current?.blur();
    }

    if (positiveNumberValuesOnly) {
      if (e.key === '-') e.preventDefault();
    }
  };

  const onPaste = (e: ClipboardEvent) => {
    if (type === 'number') {
      const text = e.clipboardData.getData('Text');
      if (!text.match(/^[0-9]|\.+$/)) e.preventDefault();

      if (positiveNumberValuesOnly) {
        if (text.match(/-/)) e.preventDefault();
      }
    }
  };

  return (
    <div className={classes}>
      {hasLabel && (
        <div className="Input-heading">
          {label && (
            <label className="Input-label" htmlFor={id}>
              {label}
              {labelSuffix && (
                <span className="Input-label-suffix"> {labelSuffix}</span>
              )}
            </label>
          )}
          {info && infoOnClick && (
            <button onClick={infoOnClick} className="Input-info" type="button">
              <Icon name="info" size="small" />
            </button>
          )}
        </div>
      )}
      <div className="Input-item">
        <input
          autoFocus={autoFocus}
          autoComplete="off"
          className="Input-input"
          disabled={disabled}
          defaultValue={defaultValue}
          id={id}
          onChange={onChange}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          onWheel={onWheel}
          placeholder={placeholder}
          ref={inputRef}
          type={type}
          value={(!(value || value === 0) && '') || value}
          {...(max && type === 'number' && { max: max })}
          {...(min && type === 'number' && { min: min })}
          {...(min === 0 && type === 'number' && { min: min })}
          {...(positiveNumberValuesOnly && min && min < 0 && { min: 0 })}
          {...(positiveNumberValuesOnly && !min && { min: 0 })}
          {...(required && { required })}
          {...(step && type === 'number' && { step })}
          {...(unit && { 'aria-describedby': `${id}-unit` })}
        />
        {unit && (
          <div className="Input-unit" id={`${id}-unit`}>
            {unit}
          </div>
        )}
        <span className="Input-border" />
        <AnimatePresence>
          {isLoading && (
            <motion.span
              className="Input-loader"
              animate={{ opacity: 1 }}
              key="Input-loader"
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ ...transDefault }}
            >
              <motion.span
                animate={{ backgroundPosition: ['0% 50%', '300% 50%'] }}
                key="Input-loader-inner"
                className="Input-loader-inner"
                transition={{
                  duration: 1.5,
                  ease: 'linear',
                  repeat: Infinity,
                }}
              />
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      {isError && <span className="Input-error">{error}</span>}
      {isWarn && <span className="Input-warning">{warning}</span>}
      {helper && <span className="Input-helper">{helper}</span>}
    </div>
  );
};

export default Input;

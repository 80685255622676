import './View.scss';

import React, { FC } from 'react';

import Loader from '../Loader/Loader';
import { ViewProps } from './View.types';
import c from 'classnames';
import useScrollTop from '../../lib/useScrollTop';

const View: FC<ViewProps> = ({
  children,
  className,
  isLoading,
  modifier,
  scrollToTop = true,
}) => {
  const classes = c(
    'View',
    {
      [`View--${modifier}`]: modifier,
      'is-loading': isLoading,
    },
    className,
  );

  useScrollTop(scrollToTop);

  if (isLoading) {
    return (
      <div className={classes}>
        <div className="View-loader">
          <Loader />
        </div>
      </div>
    );
  }

  return <div className={classes}>{children}</div>;
};

export default View;

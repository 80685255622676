import './stylesheets/index.scss';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import React, { FC } from 'react';
import { easing, transDefault, transDefaultFast } from '../../lib/config';
import { isBoolean } from '../../lib/utils';
import { ButtonProps } from './Button.types';
import Icon from '../Icon/Icon';
import c from 'classnames';

const loaderVariants = {
  animate: {
    opacity: [0, 1, 0],
    backgroundPosition: ['0% 50%', '100% 100%'],
    transition: {
      duration: 1,
      ease: 'linear',
      repeat: Infinity,
    },
  },
};

const getIconSize = (size: ButtonProps['size']) => {
  if (size === 'small' || size === 'xSmall') return 'small';
  return 'medium';
};

const Item: FC<ButtonProps> = ({
  children,
  className,
  icon,
  iconIsActive,
  isLoading,
  isSuccess,
  size,
}) => {
  const classes = c('Button-item', className);
  let itemAnimProps = {};
  let iconAnimProps = {};

  if (isLoading || isSuccess) {
    itemAnimProps = {
      animate: { y: 0 },
      exit: { y: 48 },
      initial: { y: -48 },
      layout: 'position',
      transition: transDefault,
    };
  }

  if (icon === 'arrowDown' && isBoolean(iconIsActive)) {
    if (iconIsActive) {
      iconAnimProps = {
        animate: { rotate: [0, 180] },
        transition: transDefaultFast,
      };
    } else {
      iconAnimProps = {
        animate: { rotate: [-180, 0] },
        transition: transDefaultFast,
      };
    }
  }

  return (
    <motion.div className={classes} {...itemAnimProps}>
      {children && <span className="Button-text">{children}</span>}
      <AnimatePresence initial={iconIsActive}>
        {icon && (
          <motion.span className="Button-icon" key={icon} {...iconAnimProps}>
            <Icon name={icon} size={getIconSize(size)} />
          </motion.span>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

/**
 * Note that this is to keep the spacing for the
 * absolutely positioned items above to be able to animate
 * the layout of the entire button with motion. :/
 */
const Spacer = ({
  children,
  icon,
  isSuccess,
  loading,
  loadText,
  noStates,
  size,
  sucText,
}: ButtonProps & {
  loadText?: string;
  loading?: boolean;
  noStates: boolean;
  sucText?: string;
}) => {
  return (
    <span className="Button-spacer" aria-hidden="true">
      {noStates && children && <span className="Button-text">{children}</span>}
      {loading && <span className="Button-text">{loadText}</span>}
      {isSuccess && (
        <>
          <span className="Button-text">{sucText}</span>
          <span className="Button-icon">
            <Icon name="check" size={getIconSize(size)} />
          </span>
        </>
      )}
      {icon && noStates && (
        <span className="Button-icon">
          <Icon name={icon} size={getIconSize(size)} />
        </span>
      )}
    </span>
  );
};

const Button: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  modifier = 'default',
  href,
  icon,
  iconIsActive,
  isActive,
  isLoading,
  isSuccess,
  justify,
  language = 'en',
  loadingText,
  onClick,
  outline,
  target,
  size = 'medium',
  siblingsHover,
  successText,
  tabIndex,
  type = 'button',
  variant,
  ...props
}) => {
  let loadText, sucText;
  const loading = isLoading && !isSuccess;
  const noStates = !loading && !isSuccess;
  const classes = c(className, 'Button', {
    'Button--default': modifier === 'default',
    'Button--primary': modifier === 'primary',
    'Button--secondary': modifier === 'secondary',
    '-size:s': size === 'small',
    '-size:xs': size === 'xSmall',
    '-justify:block': justify === 'block',
    '-justify:stretch': justify === 'stretch',
    '-outline': outline,
    '-outline:strong': outline === 'strong',
    'is-loading': loading,
    'is-success': isSuccess,
    'is-active': isActive,
    '-variant:success': variant === 'success',
    '-variant:error': variant === 'error',
    '-variant:warning': variant === 'warning',
    '-variant:successHover': variant === 'successHover',
    '-variant:errorHover': variant === 'errorHover',
    '-variant:warningHover': variant === 'warningHover',
    '-siblingsHover': siblingsHover,
  });

  switch (language) {
    case 'fi':
      loadText = loadingText ? loadingText : 'Ladataan…';
      sucText = successText ? successText : 'Lähetetty';
      break;
    default:
      loadText = loadingText ? loadingText : 'Loading…';
      sucText = successText ? successText : 'Sent';
  }

  if (href) {
    return (
      <a
        className={classes}
        href={href}
        onClick={onClick}
        target={target}
        {...(target === '_blank' && { rel: 'noreferrer' })}
      >
        <div className="Button-inner">
          <Item icon={icon} size={size}>
            {children}
          </Item>
          <Spacer icon={icon} noStates size={size}>
            {children}
          </Spacer>
        </div>
      </a>
    );
  }

  return (
    <AnimateSharedLayout>
      <motion.button
        aria-hidden={props['aria-hidden']}
        aria-label={props['aria-label']}
        className={classes}
        disabled={disabled}
        onClick={onClick}
        tabIndex={tabIndex}
        type={type}
        {...((isLoading || isSuccess) && { layout: true })}
      >
        <div className="Button-inner">
          <AnimatePresence initial={false}>
            {noStates && (
              <Item
                key="Button-item"
                isActive={isActive}
                iconIsActive={iconIsActive}
                icon={icon}
                size={size}
              >
                {children}
              </Item>
            )}
            {isSuccess && (
              <Item
                className="Button-item--success"
                key="Button-item--success"
                isSuccess={true}
                icon="check"
                size={size}
              >
                {sucText}
              </Item>
            )}
            {loading && (
              <Item
                className="Button-item--loader"
                isLoading={true}
                key="Button-item--loader"
              >
                {loadText}
              </Item>
            )}
            <Spacer
              icon={icon}
              isSuccess={isSuccess}
              loading={loading}
              loadText={loadText}
              noStates={noStates}
              sucText={sucText}
              size={size}
            >
              {children}
            </Spacer>
          </AnimatePresence>
        </div>
        <AnimatePresence initial={false}>
          {isSuccess && (
            <div className="Button-state">
              <motion.span
                animate={{
                  y: 0,
                }}
                className="Button-state-inner"
                initial={{
                  y: 48,
                }}
                key="Button-state"
                transition={{ ...transDefault }}
              />
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {loading && (
            <motion.span
              className="Button-loader"
              animate={{ opacity: 1 }}
              key="Button-loader"
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 1,
                ease: easing,
              }}
            >
              <motion.span
                animate="animate"
                exit="exit"
                key="Button-loader-inner"
                initial="initial"
                className="Button-loader-inner"
                variants={loaderVariants}
              />
            </motion.span>
          )}
        </AnimatePresence>
      </motion.button>
    </AnimateSharedLayout>
  );
};

export default Button;

import './stylesheets/index.scss';

import React, { FC } from 'react';
import Icon from '../Icon/Icon';
import c from 'classnames';
import { TableProps } from './Table.types';
import { useSortBy, useTable } from 'react-table';
import { getCSSVarValue } from '../../lib/utils';

const Table: FC<TableProps> = ({
  alignLastColumnRight,
  align,
  border = true,
  columns,
  columnsWidth,
  className,
  data,
  getTableProps,
  headerGroups,
  negative,
  prepareRow,
  rows,
  sort = false,
  strongHeader,
  tableOptions,
}) => {
  const defaultColumnWidth = `repeat(${columns.length}, 1fr)`;
  const customWidth = columnsWidth?.map((col: any) => `${col}fr`).join(' ');
  const columnWidth = customWidth ? customWidth : defaultColumnWidth;
  const classes = c('Table', className, {
    '-align:right': align === 'right',
    '-align:column:last:right': alignLastColumnRight,
    '-border:none': !border,
    '-header:strong': strongHeader,
    '-negative': negative,
    '-responsive': getCSSVarValue('--responsive') === 'true',
  });
  let tableInstance = { headerGroups, rows, prepareRow, getTableProps };

  if (data) {
    tableInstance = useTable(
      {
        columns: columns,
        data: data,
        ...tableOptions,
      },
      sort && useSortBy,
    );
  }

  return (
    <table
      className={classes}
      style={{
        ['--Table-columns' as any]: columnWidth,
      }}
      {...tableInstance.getTableProps()}
    >
      <thead className="Table-head">
        {tableInstance.headerGroups.map((headerGroup: any) => {
          const {
            key,
            ...headerGroupProps
          } = headerGroup.getHeaderGroupProps();
          return (
            <tr className="Table-row" key={key} {...headerGroupProps}>
              {headerGroup.headers.map((column: any) => {
                const { key } = column.getHeaderProps();

                return (
                  <th
                    className={c('Table-heading', {
                      '-text:transform:none': column.textTransform === false,
                    })}
                    {...(column.getSortByToggleProps && {
                      ...column.getHeaderProps(column.getSortByToggleProps()),
                    })}
                    key={key}
                  >
                    {column.render('header')}
                    {column.canSort && (
                      <div
                        className={`Table-heading-sort ${
                          column.isSorted ? 'is-sorted' : ''
                        }`}
                      >
                        <Icon name="select" size="small" />
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon name="selectDesc" size="small" />
                          ) : (
                            <Icon name="selectAsc" size="small" />
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody className="Table-body">
        {tableInstance.rows.map((row: any) => {
          tableInstance.prepareRow(row);
          const { key, ...rowProps } = row.getRowProps();

          return (
            <tr className="Table-row" key={key} {...rowProps}>
              {row.cells.map((cell: any) => {
                const { key, ...cellProps } = cell.getCellProps();

                return (
                  <td
                    className={c('Table-cell', {
                      '-noHeading': !cell.column.header,
                    })}
                    key={key}
                    {...cellProps}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;

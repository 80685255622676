import './Name.scss';

import React, { FC } from 'react';

import { NameProps } from './Name.types';
import c from 'classnames';

const Name: FC<NameProps> = ({
  className,
  name,
  parentHover = true,
  siblingsHover,
}) => {
  const characters = name.split(' ').map(i => i.charAt(0));
  const firstChar = characters[0]?.toUpperCase();
  let secondChar = characters[1]?.toUpperCase();
  if (!secondChar?.match(/[a-z]|[0-9]/i)) secondChar = '';

  const classes = c('Name', className, {
    '-parentHover': parentHover,
    '-siblingsHover': siblingsHover,
  });

  return (
    <div className={classes}>
      {firstChar}
      {secondChar}
    </div>
  );
};

export default Name;
